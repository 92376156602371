import React from 'react'
import {ActionsMenuLayout, MobileDrawer} from 'wix-ui-tpa/cssVars'
import {User} from '../../../../../../icons/User'
import {useWidgetProps} from '../../../hooks/widget-props'
import {isUserLoggedIn} from '../../../selectors/member'
import {Close} from './close'

export const LoginDrawer = () => {
  const {toggleLoginDrawer, promptLogin, member, logout, t} = useWidgetProps()
  const loggedIn = isUserLoggedIn(member)

  const handleLogin = () => {
    if (loggedIn) {
      logout()
    } else {
      promptLogin()
    }

    handleClose()
  }

  const handleClose = () => toggleLoginDrawer()

  return (
    <MobileDrawer isOpen onRequestClose={handleClose}>
      <div style={{width: '100%'}}>
        <ActionsMenuLayout>
          <ActionsMenuLayout.Item
            onClick={handleLogin}
            content={loggedIn ? t('logout') : t('login')}
            prefixIcon={<User />}
          />
          <ActionsMenuLayout.Item onClick={handleClose} content={t('close')} prefixIcon={<Close />} />
        </ActionsMenuLayout>
      </div>
    </MobileDrawer>
  )
}
