import React from 'react'

interface UserProps {
  className?: string
  onClick?: () => void
}

export const User = ({className, onClick = () => {}}: UserProps) => (
  <svg className={className} onClick={onClick} width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Basic_Shapes" data-name="Basic Shapes">
        <rect fill="none" width="24" height="24" />
        <path
          stroke="#000"
          strokeLinejoin="round"
          fill="none"
          d="M18.52,19.48a1,1,0,0,0,1-1v-.67s.09-2.44-3.06-3.48l-1.91-.63a1,1,0,0,1-.71-.93,1.19,1.19,0,0,1,.38-.87,4.94,4.94,0,0,0,1.55-3.51c.33-4.68-3.19-4.91-3.71-4.91H12c-.52,0-4,.23-3.7,4.91A4.9,4.9,0,0,0,9.82,11.9a1.19,1.19,0,0,1,.36.7,1,1,0,0,1-.71,1.1l-1.9.63c-3.15,1-3.05,3.48-3.05,3.48v.67a1,1,0,0,0,1,1Z"
        />
      </g>
    </g>
  </svg>
)
