import {IStyleParam, IStylesGetParam} from '@wix/tpa-settings'
import {useSettings} from '@wix/tpa-settings/react'
import settingsParams from './settingsParams'
import stylesParams from './stylesParams'

export const StyleSelectors = (get: IStylesGetParam) => {
  const {get: getSetting} = useSettings()

  const isVisible = (param: IStyleParam) =>
    get(param) !== undefined ? get(param) : getSetting(settingsParams[param.key])

  const isDateAndLocationVisible = () => isVisible(stylesParams.showDateAndLocation)
  const isButtonVisible = () => isVisible(stylesParams.showButton)
  const isDurationVisible = () => isVisible(stylesParams.showDuration)
  const isFiltersVisible = () => isVisible(stylesParams.showFilters)

  return {
    isDateAndLocationVisible,
    isButtonVisible,
    isDurationVisible,
    isFiltersVisible,
  }
}
