import {createReducer} from '@reduxjs/toolkit'
import {resetFilter, setFilter, setLocation, toggleTag} from '../actions/filter'

export const ALL_LOCATIONS: string = null

const initialState: FilterState = {
  location: ALL_LOCATIONS,
  tags: [],
}

export const filter = createReducer(initialState, builder => {
  builder
    .addCase(setLocation.pending, (state, action) => {
      state.location = action.meta.arg
    })
    .addCase(toggleTag.pending, (state, {meta: {arg}}) => {
      if (state.tags.includes(arg)) {
        state.tags = state.tags.filter(tag => tag !== arg)
      } else {
        state.tags = [...state.tags, arg]
      }
    })
    .addCase(setFilter.pending, (state, action) => {
      const {location, tags} = action.meta.arg.data

      const tagArray = Object.entries(tags ?? {})
        .filter(([, value]) => value)
        .map(([tag]) => tag)

      return {
        ...state,
        location,
        tags: tagArray,
      }
    })
    .addCase(resetFilter.pending, state => {
      state.location = ALL_LOCATIONS
      state.tags = []
    })
})

export interface FilterState {
  location: string
  tags: string[]
}
