import {isPersonalAgendaEnabled} from '@wix/wix-events-commons-statics'
import {IUser} from '@wix/yoshi-flow-editor'
import {isScheduleItemModalOpen} from '../selectors/modals'
import {getCurrentUser, logout as memberLogout, promptLogin as memberPromptLogin} from '../services/member'
import {createAsyncAction} from '../services/redux-toolkit'
import {BookmarkOrigin, createBookmark, deleteBookmark, getBookmarks} from './schedule'

export const promptLogin = createAsyncAction<{user: Partial<IUser>; imageUrl: string} | null, string | void>(
  'PROMPT_LOGIN',
  async (
    itemId,
    {
      extra: {
        api,
        controllerParams: {
          flowAPI: {
            environment: {language},
          },
          controllerConfig: {wixCodeApi},
        },
      },
      getState,
      dispatch,
    },
  ) => {
    const user = await memberPromptLogin(wixCodeApi, language)

    if (!user) {
      return null
    }

    const {imageUrl} = await api.getUserProfileImage(user.id)

    if (itemId) {
      setTimeout(() => {
        const state = getState()
        const bookmarked = Boolean(state.schedule.bookmarks.find(bookmark => bookmark.id === itemId))
        const scheduleModalOpened = isScheduleItemModalOpen(state.modals)
        const req = {
          itemId,
          origin: scheduleModalOpened ? BookmarkOrigin.ItemDetails : BookmarkOrigin.Schedule,
        }
        bookmarked ? dispatch(deleteBookmark(req)) : dispatch(createBookmark(req))
      })
    }

    return {user: getSerializableUser(user), imageUrl}
  },
)

export const logout = createAsyncAction(
  'LOGOUT',
  async (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) => {
    memberLogout(wixCodeApi)
  },
)

export const loadCurrentUser = createAsyncAction<{user: Partial<IUser>; imageUrl: string} | null>(
  'GET_CURRENT_USER',
  async (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
        api,
      },
    },
  ) => {
    const user = getCurrentUser(wixCodeApi)

    if (user?.loggedIn) {
      const {imageUrl} = await api.getUserProfileImage(user.id)
      return {user: getSerializableUser(user), imageUrl}
    } else {
      return null
    }
  },
)

const getSerializableUser = (user: IUser) => {
  const {id, loggedIn, role} = user

  return {
    id,
    loggedIn,
    role,
  }
}

export const addLoginListener = createAsyncAction<void>(
  'ADD_LOGIN_LISTENER',
  async (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
      },
      getState,
      dispatch,
    },
  ) =>
    wixCodeApi.user.onLogin(async () => {
      if (isPersonalAgendaEnabled(getState().event)) {
        await Promise.all([dispatch(loadCurrentUser()), dispatch(getBookmarks())])
      }
    }),
)

export type PromptLogin = typeof promptLogin
export type Logout = typeof logout
export type LoadCurrentUser = typeof loadCurrentUser
