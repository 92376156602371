import classNames from 'classnames'
import React from 'react'
import {DividerProps, Divider as WixDivider} from 'wix-ui-tpa/cssVars'
import {useModal} from '../../../hooks/modal'
import {classes as c} from './divider.st.css'

export const DateDivider = (props: DividerProps) => {
  const {insideModal} = useModal()
  return <WixDivider {...props} className={getClasses(insideModal, [c.date])} />
}

export const ItemDivider = (props: DividerProps) => {
  const {insideModal} = useModal()
  return <WixDivider {...props} className={getClasses(insideModal, [c.item])} />
}

export const HeaderDivider = (props: DividerProps) => {
  const {insideModal} = useModal()
  return <WixDivider {...props} className={getClasses(insideModal, [c.header])} />
}

const getClasses = (insideModal: boolean, defaultClassNames: string[] = []) =>
  classNames(...defaultClassNames, {[c.insideModal]: insideModal, [c.default]: !insideModal})
