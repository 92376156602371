import React, {useEffect} from 'react'
import {useWidgetProps} from '../../hooks/widget-props'
import {
  getScheduleItemModalItem,
  isFiltersModalOpen,
  isLoginDrawerOpen,
  isPersonalAgendaModalOpen,
  isScheduleItemModalOpen,
} from '../../selectors/modals'
import {FiltersModal} from './filters-modal/filters'
import {LoginDrawer} from './login-drawer'
import {PersonalAgendaModal} from './personal-agenda/personal-agenda'
import {ScheduleItem} from './schedule-item'

export const Modals = () => {
  const {modals, host} = useWidgetProps()
  const filtersModalOpen = isFiltersModalOpen(modals)
  const personalAgendaModalOpen = isPersonalAgendaModalOpen(modals)
  const scheduleItemModalOpen = isScheduleItemModalOpen(modals)
  const loginDrawerOpen = isLoginDrawerOpen(modals)
  const anyModalOpen = filtersModalOpen || personalAgendaModalOpen || scheduleItemModalOpen || loginDrawerOpen

  useEffect(() => {
    if (anyModalOpen) {
      host?.blockScroll?.()
    } else {
      host?.unblockScroll?.()
    }

    return () => {
      host?.unblockScroll?.()
    }
  }, [anyModalOpen])

  return (
    <>
      {filtersModalOpen && <FiltersModal />}
      {personalAgendaModalOpen && <PersonalAgendaModal />}
      {scheduleItemModalOpen && <ScheduleItem item={getScheduleItemModalItem(modals)} />}
      {loginDrawerOpen ? <LoginDrawer /> : null}
    </>
  )
}
