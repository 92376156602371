import {useEffect, useState} from 'react'
import {useWidgetProps} from '../../../hooks/widget-props'
import {ScheduleDays} from '../../../types'

// Modal continues showing days even when they are un-bookmarked
// until it is unmounted.
export const useModalBookmarkedDays = () => {
  const {bookmarksCount, bookmarkedDays, days} = useWidgetProps()
  const [cachedDays, setCachedDays] = useState<ScheduleDays>(bookmarkedDays)

  useEffect(() => {
    for (const day in cachedDays) {
      if (cachedDays.hasOwnProperty(day)) {
        cachedDays[day].items = cachedDays[day].items.map(({id}) => days[day].items.find(dayItem => dayItem.id === id))
      }
    }

    setCachedDays({...cachedDays})
  }, [bookmarksCount])

  return {
    days: cachedDays,
  }
}
