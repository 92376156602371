import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {
  Button,
  ButtonPriority,
  Checkbox,
  CheckboxGroup,
  Dialog,
  RadioButton,
  RadioButtonGroup,
  Text,
  TextTypography,
} from 'wix-ui-tpa/cssVars'
import {ModalContext, ModalType} from '../../../hooks/modal'
import {useWidgetProps} from '../../../hooks/widget-props'
import {classes as modalClasses} from '../modal.st.css'
import {classes} from './filters.st.css'
import {FilterFormData} from './interfaces'

export const FiltersModal = () => {
  const {tags, locations, selectedLocation, setFilter, toggleFiltersModal, resetFilter, t, host} = useWidgetProps()
  const {isMobile} = useEnvironment()

  const defaultValues = {
    tags: Object.fromEntries(
      tags.map(({value: name, checked}) => {
        return [name, checked]
      }),
    ),
    location: selectedLocation,
  }

  const {handleSubmit, control} = useForm({
    defaultValues,
  })

  const onSubmit = async (data: FilterFormData) => {
    setFilter({data})
    toggleFiltersModal()
  }

  const handleReset = async () => {
    resetFilter()
    toggleFiltersModal()
  }

  const preview = host.viewMode === 'Preview'

  return (
    <ModalContext.Provider value={{insideModal: true, modalType: ModalType.DEFAULT}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          className={classNames(modalClasses.root, modalClasses.wiredModal, {
            [modalClasses.previewMode]: preview,
            [modalClasses.mobile]: isMobile,
          })}
          isOpen
          data-hook={DH.MOBILE_FILTER_MODAL}
          onClose={() => toggleFiltersModal()}
          contentClassName={classes.modalContent}
          closeButtonAriaLabel={t('aria.dialog.close')}
        >
          <Text className={classes.title} typography={TextTypography.smallTitle} tagName="h2">
            {t('schedulePlaces.mobileFilterTitle')}
          </Text>
          <div className={classes.formContainer}>
            <Controller
              control={control}
              name="location"
              render={({field: {name, value, onChange}}) => (
                <RadioButtonGroup
                  name={name}
                  label={t('schedulePlaces.mobileFilterLocations')}
                  value={value}
                  onChange={onChange}
                >
                  {locations.map(({id, value: locationValue}) => (
                    <RadioButton
                      key={id}
                      data-hook={DH.MOBILE_FILTER_LOCATION_RADIO}
                      className={classes.radio}
                      value={id}
                      label={locationValue}
                    />
                  ))}
                </RadioButtonGroup>
              )}
            />
            {!!tags.length && (
              <CheckboxGroup label={t('schedulePlaces.mobileFilterTags')} className={classes.checkboxGroup}>
                {tags.map(({value, title}) => (
                  <Controller
                    key={value}
                    control={control}
                    name={`tags.${value}`}
                    render={({field: {onChange, name, value: tagValue}}) => (
                      <Checkbox
                        data-hook={DH.MOBILE_FILTER_TAG_CHECKBOX}
                        name={name}
                        onChange={({checked}) => {
                          onChange(checked)
                        }}
                        label={title}
                        checked={Boolean(tagValue)}
                      />
                    )}
                  />
                ))}
              </CheckboxGroup>
            )}
          </div>
          <div className={classes.actions}>
            <Button
              upgrade
              data-hook={DH.MOBILE_FILTER_RESET}
              priority={ButtonPriority.basicSecondary}
              className={classes.action}
              onClick={handleReset}
            >
              {t('schedulePlaces.mobileFilterReset')}
            </Button>
            <Button
              upgrade
              data-hook={DH.MOBILE_FILTER_SUBMIT}
              priority={ButtonPriority.basic}
              className={classes.action}
              type="submit"
            >
              {t('schedulePlaces.mobileFilterSubmit')}
            </Button>
          </div>
        </Dialog>
      </form>
    </ModalContext.Provider>
  )
}
