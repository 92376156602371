import {
  DEFAULT_LANGUAGE,
  EVENTS_APP_ID,
  EVENTS_WEB_API_BASE,
  MAIN_DC_URL,
  getDeviceTimeZoneId,
  isLanguageBabelSupported,
} from '@wix/wix-events-commons-statics'
import {ITrackEventName, ITrackEventParams, IWixAPI} from '@wix/yoshi-flow-editor'

export const getSiteStructure = (wixCodeApi: IWixAPI) => wixCodeApi.site.getSiteStructure({includePageId: true})

export const getPageInfo = async (wixCodeApi: IWixAPI) => {
  try {
    const siteStructure = await getSiteStructure(wixCodeApi)
    const homePage = siteStructure.pages.find(page => page?.isHomePage)
    return {isHomePage: homePage?.id === wixCodeApi.site.currentPage?.id}
  } catch (e) {
    console.log(e)
    return {isHomePage: false}
  }
}

export const isSSR = (wixCodeApi: IWixAPI) => wixCodeApi.window.rendering.env === 'backend'

export const getWriteServerBaseUrl = (wixCodeApi: IWixAPI): string =>
  `${getBaseUrl(wixCodeApi, MAIN_DC_URL)}${EVENTS_WEB_API_BASE}`

export const getBaseUrl = (wixCodeApi: IWixAPI, fallback = '') => {
  if (isSSR(wixCodeApi)) {
    return parseBaseUrl(wixCodeApi).root
  } else {
    return fallback
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

const getMultilingualFullLocale = ({window: {multilingual}}: IWixAPI): string | undefined => {
  if (multilingual.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(lang => lang.languageCode === multilingual.currentLanguage)
    return siteLanguage?.locale
  }
}

export const getLocale = (wixCodeApi: IWixAPI) =>
  getMultilingualFullLocale(wixCodeApi) || wixCodeApi.site.regionalSettings

export const getLanguage = (wixCodeApi: IWixAPI) => {
  const currentLanguage = wixCodeApi.window?.multilingual?.currentLanguage
  const language = currentLanguage || wixCodeApi.site.language

  return isLanguageBabelSupported(language) ? language : DEFAULT_LANGUAGE
}

export const getMultilingualLanguage = (wixCodeApi: IWixAPI): string | undefined => {
  if (wixCodeApi.window?.multilingual?.isEnabled) {
    return wixCodeApi.window.multilingual.currentLanguage
  }
  return undefined
}

export const isMainLanguage = (wixCodeApi: IWixAPI): boolean | undefined => {
  if (wixCodeApi.window?.multilingual?.isEnabled) {
    return Boolean(
      wixCodeApi.window.multilingual.siteLanguages?.find(
        ({languageCode}) => languageCode === wixCodeApi.window.multilingual.currentLanguage,
      ),
    )
  }
  return true
}

export const getTimezone = (wixCodeApi: IWixAPI) => wixCodeApi.site.timezone || getDeviceTimeZoneId()

export const isMobile = (wixCodeApi: IWixAPI) => wixCodeApi.window.formFactor === 'Mobile'

export const isEditor = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Editor'
export const isPreview = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Preview'
export const isSite = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Site'

export const trackEvent = (wixCodeApi: IWixAPI, eventName: ITrackEventName, params: ITrackEventParams) =>
  wixCodeApi.window.trackEvent(eventName, {...params, appDefId: EVENTS_APP_ID})

export const getPageUrl = async (wixCodeApi: IWixAPI): Promise<string> => {
  const {url} = await wixCodeApi.site.getSectionUrl({
    sectionId: 'events',
    appDefinitionId: EVENTS_APP_ID,
  })
  return url
}
