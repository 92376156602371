import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {Button as UIButton} from 'wix-ui-tpa/cssVars'
import {useSettings} from '../../../hooks/settings'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import settingsParams from '../../../settingsParams'
import {NavigateToEventDetails} from '../../actions/navigate'
import {classes} from './button.st.css'

interface ButtonProps {
  eventDetailsUrl: string
  fullWidth?: boolean
  navigate: NavigateToEventDetails
  isSite: boolean
}

export const Button: React.FC<ButtonProps> = ({eventDetailsUrl, fullWidth, isSite, navigate}) => {
  const settings = useSettings()
  const {
    allBreakpoints: {isButtonVisible},
  } = useVisibilityStyles()

  if (!isButtonVisible()) {
    return null
  }

  return (
    <UIButton
      className={classNames(classes.root, {[classes.fullWidth]: fullWidth})}
      data-hook={DH.GET_TICKETS_BUTTON}
      upgrade
      as="a"
      href={isSite ? eventDetailsUrl : undefined}
      onClick={isSite ? null : () => navigate()}
      fullWidth={fullWidth}
    >
      {settings.get(settingsParams.buttonText)}
    </UIButton>
  )
}
