import {IUser, IWixAPI} from '@wix/yoshi-flow-editor'

export const promptLogin = (wixCodeApi: IWixAPI, lang: string): Promise<IUser | null> =>
  new Promise(async (resolve, reject) => {
    try {
      const user = await wixCodeApi.user.promptLogin({
        mode: 'login',
        lang,
      })

      resolve(user)
    } catch (e) {
      if (typeof e !== 'string') {
        reject(e)
        return
      }

      resolve(null)
    }
  })

export const logout = (wixCodeApi: IWixAPI) => {
  wixCodeApi.user.logout()
}

export const getCurrentUser = (wixCodeApi: IWixAPI) => wixCodeApi.user.currentUser
