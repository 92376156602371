import React, {useContext} from 'react'

export interface ModalContextStructure {
  insideModal: boolean
  modalType: ModalType
}

export enum ModalType {
  DEFAULT,
  SCHEDULE_ITEM,
}

export const ModalContext = React.createContext<ModalContextStructure>({
  insideModal: false,
  modalType: ModalType.DEFAULT,
})

export const useModal = () => {
  const {insideModal, modalType} = useContext(ModalContext)

  return {
    insideModal,
    modalType,
  }
}
