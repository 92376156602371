import type {ModalsState} from '../reducers/modals'

export const isFiltersModalOpen = (state: ModalsState) => state.filtersModal.open

export const isScheduleItemModalOpen = (state: ModalsState) => state.scheduleItemModal.open

export const isPersonalAgendaModalOpen = (state: ModalsState) => state.personalAgendaModal.open

export const isLoginDrawerOpen = (state: ModalsState) => state.loginDrawer.open

export const getScheduleItemModalItem = (state: ModalsState) => state.scheduleItemModal.item
