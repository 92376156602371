import {ALL_LOCATIONS, FilterState} from '../reducers/filter'

export const getFilterButtonText = (state: FilterState, t: Function) => {
  const items = state.tags.filter(item => item !== ALL_LOCATIONS)

  if (state.location) {
    items.unshift(state.location)
  }

  if (!items.length) {
    return t('schedulePlaces.mobileFilterButton')
  }

  return items.join(', ')
}
