export const ROOT_COMPONENT_ID = 'schedule-page-root'

export enum SettingsUpdatedEvent {
  ForceShow = 'forceShow',
  ForceHide = 'forceHide',
}

export enum SettingsTab {
  ButtonDesign = 'buttonDesign',
}
