import React from 'react'

interface TimeProps {
  className?: string
  dateTime?: string
}

export const Time: React.FC<TimeProps> = ({children, dateTime, className}) => (
  <time className={className} dateTime={dateTime}>
    {children || <>&nbsp;</>}
  </time>
)
