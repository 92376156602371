import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {HeaderDivider} from '../../schedule/divider'
import {Login} from '../login'
import {MyAgendaButton} from '../my-agenda-button'
import s from './personal-agenda-header.scss'
import {PersonalAgendaHeaderProps} from '.'

export const PersonalAgendaHeader = ({membersAreaInstalled}: PersonalAgendaHeaderProps) => {
  const {isMobile} = useEnvironment()
  return (
    <div className={classNames(s.topControls, {[s.mobile]: isMobile})}>
      <div className={s.topControlsContent}>
        <MyAgendaButton />
        {!membersAreaInstalled ? (
          <div className={s.topControlsLogin}>
            <Login />
          </div>
        ) : null}
      </div>
      <HeaderDivider />
    </div>
  )
}
