import {Event} from '@wix/ambassador-wix-events-web/types'
import {addQueryParams} from '@wix/panda-js-utils'
import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'

export const getSlug = (controllerParams: ControllerParams) => {
  if (controllerParams.flowAPI.environment.isEditor) {
    return null
  }
  const path = controllerParams.controllerConfig.wixCodeApi.location.path
  return path[path.length - 1]
}

export const getEventDetailsUrl = async (controllerParams: ControllerParams, event: Event) => {
  const url = await controllerParams.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId: 'events',
    appDefinitionId: EVENTS_APP_ID,
  })
  const {query} = controllerParams.controllerConfig.wixCodeApi.location

  return addQueryParams(`${url.url}/${event?.slug}`, query)
}

export const getEventDetailsRelativeUrl = async (controllerParams: ControllerParams, event: Event) => {
  const url = await controllerParams.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId: 'events',
    appDefinitionId: EVENTS_APP_ID,
  })
  return url.relativeUrl + `/${event?.slug}`
}
