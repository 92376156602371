import React from 'react'

export const Close = () => (
  <svg
    width="24px"
    height="24px"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 18L6 6" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeOpacity="1" />
    <path d="M6 18L18 6" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeOpacity="1" />
  </svg>
)
