import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {Star} from '../../../../../../icons/Star'
import {StarFilled} from '../../../../../../icons/StarFilled'
import {PersonalAgendaOrigin} from '../../../actions/schedule'
import {useWidgetProps} from '../../../hooks/widget-props'
import s from './my-agenda-button.scss'
import {classes as c} from './my-agenda-button.st.css'

export const MyAgendaButton = () => {
  const {bookmarksCount, t, viewPersonalAgenda} = useWidgetProps()
  const {isMobile} = useEnvironment()
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  return (
    <TextButton
      data-hook={DH.MY_AGENDA_BUTTON}
      className={classNames(c.button, s.button, {
        [s.mobile]: isMobile,
        [s.mobileFontSize]: isMobile && mobileFontOverridesEnabled,
      })}
      prefixIcon={bookmarksCount ? <StarFilled className={s.starFilled} /> : <Star className={s.star} />}
      priority={TextButtonPriority.primary}
      onClick={() => viewPersonalAgenda({origin: PersonalAgendaOrigin.Schedule})}
    >
      {t('myAgenda.viewButton', {count: bookmarksCount})}
    </TextButton>
  )
}
