import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars'
import {useSettings} from '../../../hooks/settings'
import settingsParams, {isFullButtonStyle} from '../../../settingsParams'
import {ItemDivider} from '../schedule/divider'
import {classes} from './no-results.st.css'
import {NoResultsProps} from '.'

export const NoResults: React.FC<NoResultsProps> = ({onLinkClick, t}) => {
  const settings = useSettings()
  const buttonStyle = settings.get(settingsParams.buttonStyle)
  const full = isFullButtonStyle(buttonStyle)
  return (
    <div className={classes.container}>
      <ItemDivider />
      <div className={classes.innerContainer}>
        <Text className={classes.text}>{t('noResultsText')}</Text>
        <TextButton
          className={full ? classes.fullButton : classes.hollowButton}
          priority={TextButtonPriority.primary}
          data-hook={DH.RESET_FILTERS_BUTTON}
          onClick={() => onLinkClick()}
        >
          {t('noResultsLink')}
        </TextButton>
      </div>
      <ItemDivider />
    </div>
  )
}
